import React from 'react'
import HtmlParser from '../HtmlParser'
import { useWebformElement, WebformElementWrapper } from 'gatsby-drupal-webform'

const WebformFieldMarkup = ({ element, error }) => {
    const [inputProps, settings] = useWebformElement(element, {
        name: element.name,
        type: 'markup'
    })

    return (
        <WebformElementWrapper
            className="field md:col-span-2"
            settings={settings}
            error={error}
            labelFor={element.name}
            labelClassName={`label${inputProps.required ? ` required` : ``}`}
        >
            {element.attributes.map((attribute, index) => {
                if (attribute.name === "#markup") {
                    return (
                        <div key={attribute.name + index} className="content">
                            <HtmlParser html={attribute.value} />
                        </div>
                    );
                }

                return null;
            })}
        </WebformElementWrapper>
    )
}

export default WebformFieldMarkup
