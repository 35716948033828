import React, {useState} from 'react'
import {useWebformElement, WebformElementWrapper} from 'gatsby-drupal-webform'

const WebformFieldCheckboxes = ({element, error}) => {
    const [checked, setChecked] = useState([]);

    const [inputProps, settings] = useWebformElement(element, {
        name: element.name,
        type: 'checkbox'
    });

    const handleChange = (value) => {
        if (checked.includes(value)) {
            const index = checked.indexOf(value);
            
            if (index > -1) {
                checked.splice(index, 1);
                setChecked([...checked]);
            }
        } else {
            setChecked([
                ...checked,
                value
            ]);
        }
    }

    return (
        <WebformElementWrapper
            className="field md:col-span-2"
            settings={settings}
            error={error}
            labelFor={element.name}
            labelClassName={`label${inputProps.required ? ` required` : ``}`}
        >
            <div className="checkboxes flex flex-col lg:flex-row gap-4 lg:gap-8">
                {element.options.map(({label, value}, index) => {
                    let elementId = `${element.name}_${value}`
                    let isChecked = checked.includes(elementId);

                    return (
                        <div key={index} className="control relative flex">
                            <input id={`field_${elementId}`} name={element.name} value={value} className="checkbox sr-only top-full" onChange={() => handleChange(elementId)} {...inputProps} />

                            <label htmlFor={`field_${elementId}`} className={`checkbox-icon${isChecked ? ` checked` : ``}`}>
                                <i className={`mr-2 ${isChecked ? `fas fa-check-square` : `fas fa-square`}`}></i>
                            </label>

                            <label htmlFor={`field_${elementId}`} className="label-inline px-2">{label}</label>
                        </div>
                    )
                })}
            </div>
        </WebformElementWrapper>
    )
}

export default WebformFieldCheckboxes
