import React from "react";
import {graphql} from "gatsby";
import {getParagraph} from "../helpers/paragraph-helpers";
import Layout from "../components/Layout";
import Metas from "../components/Metas";
import Form from "../components/Form";
import MenuListItem from "../components/ListItems/MenuListItem";
import Breadcrumb from "../components/Breadcrumb";
import HtmlParser from "../components/HtmlParser";

const Page = ({data}) => {

    const paragraphs = data.node.relationships.paragraphs.map(getParagraph);

    return (
        <Layout contentType={data.node.internal.type}>
            <Metas title={data.node.title}/>
            <section className="section page-content">
                <div className="container mx-auto px-4">
                    <Breadcrumb/>

                    <h1 className="title h1 text-primary" data-typesense-field="title">{data.node.title}</h1>

                    {data.node.body && (
                        <div className="block content mb-8" data-typesense-field="body">
                            <HtmlParser html={data.node.body.value}/>
                        </div>
                    )}

                    <div className="paragraphs-list">
                        {paragraphs}
                    </div>

                    {data.node.relationships.webform && (
                        <section className="page-webform my-16">
                            <Form
                                webform={data.node.relationships.webform}
                                className="grid grid-cols-1 md:grid-cols-2 gap-8"
                            />
                        </section>
                    )}

                    {data.children && data.children.nodes.length > 0 && (
                        <div className="page-menu-children my-16">
                            <div
                                className="columns grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
                                {data.children.nodes.map((item, index) => (
                                    <div key={index} className="column portal-link h-full">
                                        <MenuListItem item={item}/>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </Layout>
    )
}

export default Page

export const query = graphql`
    query ($internalId: Int!, $menuItemId: String) {
        node: nodePage(
            status: {eq: true},
            drupal_internal__nid: {eq: $internalId}
        ) {
            title
            body {
                summary
                value
            }
            changed
            relationships {
                paragraphs: field_paragraphes {
                    type: __typename
                    ... on Node {
                        ...TextParagraph
                        ...BannerParagraph
                        ...HighlightedParagraph
                        ...ListParagraph
                        ...FilesMultiuploadParagraph
                        ...AccordionParagraph
                        ...CollapseParagraph
                        ...ImageGalleryParagraph
                        ...ImageTextParagraph
                        ...TextImageParagraph
                    }
                }
                webform: field_webform {
                    title
                    drupal_internal__id
                    elements {
                        name
                        type
                        attributes {
                            name
                            value
                        }
                        options {
                            label
                            value
                        }
                    }
                }
            }
            internal {
                type
            }
        }
        children: allMenuLinkContentMenuLinkContent(
            filter: {
                enabled: {eq: true},
                menu_name: {eq: "main"},
                drupal_parent_menu_item: {
                    eq: $menuItemId,
                    ne: null
                }
            }
            sort: {fields: weight, order: ASC}
        ) {
            nodes {
                title
                drupal_parent_menu_item
                drupal_id
                link {
                    uri
                    options {
                        attributes {
                            data_has_icon
                            data_icon
                            data_text_invisible
                            target
                        }
                    }
                }
            }
        }
    }
`
