import React, {useState} from 'react'
import {useWebformElement, WebformElementWrapper} from 'gatsby-drupal-webform'

const WebformFieldRadios = ({element, error}) => {
    const [checked, setChecked] = useState(null)

    const [inputProps, settings] = useWebformElement(element, {
        name: element.name,
        type: 'radio'
    })

    return (
        <WebformElementWrapper
            className="field md:col-span-2"
            settings={settings}
            error={error}
            labelFor={element.name}
            labelClassName={`label${inputProps.required ? ` required` : ``}`}
        >
            <div className="radios flex flex-col lg:flex-row -mx-4">
                {element.options.map(({label, value}, index) => {
                    let elementId = `${element.name}_${value}`
                    let isChecked = checked === elementId

                    return (
                        <div key={index} className="relative control flex px-4">
                            <input id={elementId} name={element.name} value={value} className="radio sr-only top-full" onChange={() => setChecked(elementId)} {...inputProps} />

                            <label htmlFor={elementId} className={`radio-icon${isChecked  ? ` checked` : ``}`}>
                                <i className={isChecked ? `fas fa-check-circle` : `fas fa-circle`}></i>
                            </label>

                            <label htmlFor={elementId} className="label-inline">{label}</label>
                        </div>
                    )
                })}
            </div>
        </WebformElementWrapper>
    )
}

export default WebformFieldRadios
