import React, {useState} from 'react'
import {useWebformElement, WebformElementWrapper} from 'gatsby-drupal-webform'
import { Switch } from '@headlessui/react'

const WebformFieldCheckbox = ({element, error}) => {
    const [inputProps, settings] = useWebformElement(element, {
        name: element.name,
        type: 'checkbox'
    })

    const [checked, setChecked] = useState(inputProps.value ? inputProps.value : false)

    return (
        <WebformElementWrapper
            className="field md:col-span-2 flex align-baseline"
            settings={settings}
            error={error}
            labelFor={`field_${element.name}`}
            labelClassName={`hidden`}
        >
            <Switch.Group>
                <div className="control-inline relative flex-initial">
                    <input id={`field_${element.name}`} name={element.name} type="checkbox" checked={checked} className="sr-only top-full" {...inputProps} />    
                    <Switch
                        labelFor={`field_${element.name}`}
                        checked={checked}
                        onChange={setChecked}
                        className={`${
                            checked ? 'bg-secondary' : 'bg-gray-200'
                        } relative inline-flex items-center h-6 rounded-full w-11`}
                    >
                        <span
                            className={`${
                                checked ? 'translate-x-6' : 'translate-x-1'
                            } inline-block w-4 h-4 transform bg-white rounded-full`}
                        />
                    </Switch>
                </div>
                <Switch.Label className={`label-inline ml-4 ${inputProps.required ? `required` : ``}`}>
                    {settings.attributes.title}
                </Switch.Label>
            </Switch.Group>
        </WebformElementWrapper>
    )
}

export default WebformFieldCheckbox
